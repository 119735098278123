/* Converted Tailwind Config to scss */
$tw-screens-sm: 640px;
$tw-screens-md: 768px;
$tw-screens-lg: 1290px;
$tw-screens-xl: 1500px;
$tw-screens-2xl: 1440px;

$tw-maxWidth-75pct: 75%;
$tw-maxWidth-xl: 1500px;
$tw-maxWidth-full: 100%;

$tw-container-center: true;
$tw-container-padding-default: 1rem;
$tw-container-padding-sm: 2rem;
$tw-container-padding-lg: 4rem;
$tw-container-padding-xl: 5rem;

$tw-fontFamily-base: (Open Sans,sans-serif);
$tw-fontFamily-primary: (Barlow,sans-serif);

$tw-borderRadius-none: 0;
$tw-borderRadius-default: 3px;

$tw-fontSize-xs: 0.64em;
$tw-fontSize-sm: 0.8em;
$tw-fontSize-base: 1em;
$tw-fontSize-md: 1em;
$tw-fontSize-lg: 1.25em;
$tw-fontSize-xl: 1.56em;
$tw-fontSize-2xl: 1.95em;
$tw-fontSize-3xl: 2.44em;
$tw-fontSize-4xl: 3.05em;
$tw-fontSize-5xl: 3.81em;
$tw-fontSize-6xl: 4.76em;
$tw-fontSize-pxs: 11.26px;
$tw-fontSize-psm: 15px;
$tw-fontSize-pbase: 1em;
$tw-fontSize-pmd: 18px;
$tw-fontSize-plg: 26.66px;
$tw-fontSize-pxl: 35.54px;
$tw-fontSize-p2xl: 47.37px;
$tw-fontSize-p3xl: 63.15px;
$tw-fontSize-p4xl: 84.17px;
$tw-fontSize-p5xl: 112.2px;
$tw-fontSize-p6xl: 149.57px;

$tw-colors-light: #FFFFFF;
$tw-colors-dark: #1F2E3D;
$tw-colors-primary-default: #03699E;
$tw-colors-primary-light: #44BCE8;
$tw-colors-primary-dark: #203C66;
$tw-colors-secondary-default: #57D36C;
$tw-colors-gray-lighter: #F4F6F8;
$tw-colors-gray-light: #E2E8EE;
$tw-colors-gray-default: #BCC6CF;
$tw-colors-gray-dark: #5B636B;

$tw-fill-current: #FFFFFF;
$tw-fill-primary-default: #03699E;
$tw-fill-primary-light: #44BCE8;
$tw-fill-primary-dark: #203C66;
$tw-fill-secondary: #57D36C;

$tw-spacing-0: 0;
$tw-spacing-1: 0.25rem;
$tw-spacing-2: 0.5rem;
$tw-spacing-3: 0.75rem;
$tw-spacing-4: 1rem;
$tw-spacing-5: 1.25rem;
$tw-spacing-6: 1.5rem;
$tw-spacing-8: 2rem;
$tw-spacing-10: 2.5rem;
$tw-spacing-12: 3rem;
$tw-spacing-16: 4rem;
$tw-spacing-20: 5rem;
$tw-spacing-24: 6rem;
$tw-spacing-32: 8rem;
$tw-spacing-40: 10rem;
$tw-spacing-48: 12rem;
$tw-spacing-56: 14rem;
$tw-spacing-64: 16rem;
$tw-spacing-px: 1px;

$tw-backgroundColor-light: #FFFFFF;
$tw-backgroundColor-dark: #1F2E3D;
$tw-backgroundColor-primary-default: #03699E;
$tw-backgroundColor-primary-light: #44BCE8;
$tw-backgroundColor-primary-dark: #203C66;
$tw-backgroundColor-secondary-default: #57D36C;
$tw-backgroundColor-gray-lighter: #F4F6F8;
$tw-backgroundColor-gray-light: #E2E8EE;
$tw-backgroundColor-gray-default: #BCC6CF;
$tw-backgroundColor-gray-dark: #5B636B;

$tw-backgroundOpacity-0: 0;
$tw-backgroundOpacity-25: 0.25;
$tw-backgroundOpacity-50: 0.5;
$tw-backgroundOpacity-75: 0.75;
$tw-backgroundOpacity-100: 1;

$tw-backgroundPosition-bottom: bottom;
$tw-backgroundPosition-center: center;
$tw-backgroundPosition-left: left;
$tw-backgroundPosition-left-bottom: left bottom;
$tw-backgroundPosition-left-top: left top;
$tw-backgroundPosition-right: right;
$tw-backgroundPosition-right-bottom: right bottom;
$tw-backgroundPosition-right-top: right top;
$tw-backgroundPosition-top: top;

$tw-backgroundSize-auto: auto;
$tw-backgroundSize-cover: cover;
$tw-backgroundSize-contain: contain;

$tw-borderColor-light: #FFFFFF;
$tw-borderColor-dark: #1F2E3D;
$tw-borderColor-primary-default: #03699E;
$tw-borderColor-primary-light: #44BCE8;
$tw-borderColor-primary-dark: #203C66;
$tw-borderColor-secondary-default: #57D36C;
$tw-borderColor-gray-lighter: #F4F6F8;
$tw-borderColor-gray-light: #E2E8EE;
$tw-borderColor-gray-default: #BCC6CF;
$tw-borderColor-gray-dark: #5B636B;
$tw-borderColor-default: currentColor;

$tw-borderOpacity-0: 0;
$tw-borderOpacity-25: 0.25;
$tw-borderOpacity-50: 0.5;
$tw-borderOpacity-75: 0.75;
$tw-borderOpacity-100: 1;

$tw-borderWidth-0: 0;
$tw-borderWidth-2: 2px;
$tw-borderWidth-4: 4px;
$tw-borderWidth-8: 8px;
$tw-borderWidth-default: 1px;

$tw-boxShadow-xs: (0 0 0 1px rgba(0, 0, 0, 0.05));
$tw-boxShadow-sm: (0 1px 2px 0 rgba(0, 0, 0, 0.05));
$tw-boxShadow-default: (0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06));
$tw-boxShadow-md: (0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06));
$tw-boxShadow-lg: (0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05));
$tw-boxShadow-xl: (0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04));
$tw-boxShadow-2xl: (0 25px 50px -12px rgba(0, 0, 0, 0.25));
$tw-boxShadow-inner: (inset 0 2px 4px 0 rgba(0, 0, 0, 0.06));
$tw-boxShadow-outline: (0 0 0 3px rgba(66, 153, 225, 0.5));
$tw-boxShadow-none: none;

$tw-cursor-auto: auto;
$tw-cursor-default: default;
$tw-cursor-pointer: pointer;
$tw-cursor-wait: wait;
$tw-cursor-text: text;
$tw-cursor-move: move;
$tw-cursor-not-allowed: not-allowed;

$tw-divideColor-light: #FFFFFF;
$tw-divideColor-dark: #1F2E3D;
$tw-divideColor-primary-default: #03699E;
$tw-divideColor-primary-light: #44BCE8;
$tw-divideColor-primary-dark: #203C66;
$tw-divideColor-secondary-default: #57D36C;
$tw-divideColor-gray-lighter: #F4F6F8;
$tw-divideColor-gray-light: #E2E8EE;
$tw-divideColor-gray-default: #BCC6CF;
$tw-divideColor-gray-dark: #5B636B;
$tw-divideColor-default: currentColor;

$tw-divideOpacity-0: 0;
$tw-divideOpacity-25: 0.25;
$tw-divideOpacity-50: 0.5;
$tw-divideOpacity-75: 0.75;
$tw-divideOpacity-100: 1;

$tw-divideWidth-0: 0;
$tw-divideWidth-2: 2px;
$tw-divideWidth-4: 4px;
$tw-divideWidth-8: 8px;
$tw-divideWidth-default: 1px;

$tw-flex-1: 1 1 0%;
$tw-flex-auto: 1 1 auto;
$tw-flex-initial: 0 1 auto;
$tw-flex-none: none;

$tw-flexGrow-0: 0;
$tw-flexGrow-default: 1;

$tw-flexShrink-0: 0;
$tw-flexShrink-default: 1;

$tw-fontWeight-hairline: 100;
$tw-fontWeight-thin: 200;
$tw-fontWeight-light: 300;
$tw-fontWeight-normal: 400;
$tw-fontWeight-medium: 500;
$tw-fontWeight-semibold: 600;
$tw-fontWeight-bold: 700;
$tw-fontWeight-extrabold: 800;
$tw-fontWeight-black: 900;

$tw-height-0: 0;
$tw-height-1: 0.25rem;
$tw-height-2: 0.5rem;
$tw-height-3: 0.75rem;
$tw-height-4: 1rem;
$tw-height-5: 1.25rem;
$tw-height-6: 1.5rem;
$tw-height-8: 2rem;
$tw-height-10: 2.5rem;
$tw-height-12: 3rem;
$tw-height-16: 4rem;
$tw-height-20: 5rem;
$tw-height-24: 6rem;
$tw-height-32: 8rem;
$tw-height-40: 10rem;
$tw-height-48: 12rem;
$tw-height-56: 14rem;
$tw-height-64: 16rem;
$tw-height-auto: auto;
$tw-height-px: 1px;
$tw-height-full: 100%;
$tw-height-screen: 100vh;

$tw-inset-0: 0;
$tw-inset-auto: auto;

$tw-letterSpacing-tighter: -0.05em;
$tw-letterSpacing-tight: -0.025em;
$tw-letterSpacing-normal: 0;
$tw-letterSpacing-wide: 0.025em;
$tw-letterSpacing-wider: 0.05em;
$tw-letterSpacing-widest: 0.1em;

$tw-lineHeight-3: .75rem;
$tw-lineHeight-4: 1rem;
$tw-lineHeight-5: 1.25rem;
$tw-lineHeight-6: 1.5rem;
$tw-lineHeight-7: 1.75rem;
$tw-lineHeight-8: 2rem;
$tw-lineHeight-9: 2.25rem;
$tw-lineHeight-10: 2.5rem;
$tw-lineHeight-none: 1;
$tw-lineHeight-tight: 1.25;
$tw-lineHeight-snug: 1.375;
$tw-lineHeight-normal: 1.5;
$tw-lineHeight-relaxed: 1.625;
$tw-lineHeight-loose: 2;

$tw-listStyleType-none: none;
$tw-listStyleType-disc: disc;
$tw-listStyleType-decimal: decimal;

$tw-margin-0: 0;
$tw-margin-1: 0.25rem;
$tw-margin-2: 0.5rem;
$tw-margin-3: 0.75rem;
$tw-margin-4: 1rem;
$tw-margin-5: 1.25rem;
$tw-margin-6: 1.5rem;
$tw-margin-8: 2rem;
$tw-margin-10: 2.5rem;
$tw-margin-12: 3rem;
$tw-margin-16: 4rem;
$tw-margin-20: 5rem;
$tw-margin-24: 6rem;
$tw-margin-32: 8rem;
$tw-margin-40: 10rem;
$tw-margin-48: 12rem;
$tw-margin-56: 14rem;
$tw-margin-64: 16rem;
$tw-margin-auto: auto;
$tw-margin-px: 1px;
$tw-margin--1: -0.25rem;
$tw-margin--2: -0.5rem;
$tw-margin--3: -0.75rem;
$tw-margin--4: -1rem;
$tw-margin--5: -1.25rem;
$tw-margin--6: -1.5rem;
$tw-margin--8: -2rem;
$tw-margin--10: -2.5rem;
$tw-margin--12: -3rem;
$tw-margin--16: -4rem;
$tw-margin--20: -5rem;
$tw-margin--24: -6rem;
$tw-margin--32: -8rem;
$tw-margin--40: -10rem;
$tw-margin--48: -12rem;
$tw-margin--56: -14rem;
$tw-margin--64: -16rem;
$tw-margin--px: -1px;

$tw-maxHeight-full: 100%;
$tw-maxHeight-screen: 100vh;

$tw-minHeight-0: 0;
$tw-minHeight-full: 100%;
$tw-minHeight-screen: 100vh;

$tw-minWidth-0: 0;
$tw-minWidth-full: 100%;

$tw-objectPosition-bottom: bottom;
$tw-objectPosition-center: center;
$tw-objectPosition-left: left;
$tw-objectPosition-left-bottom: left bottom;
$tw-objectPosition-left-top: left top;
$tw-objectPosition-right: right;
$tw-objectPosition-right-bottom: right bottom;
$tw-objectPosition-right-top: right top;
$tw-objectPosition-top: top;

$tw-opacity-0: 0;
$tw-opacity-25: 0.25;
$tw-opacity-50: 0.5;
$tw-opacity-75: 0.75;
$tw-opacity-100: 1;

$tw-order-1: 1;
$tw-order-2: 2;
$tw-order-3: 3;
$tw-order-4: 4;
$tw-order-5: 5;
$tw-order-6: 6;
$tw-order-7: 7;
$tw-order-8: 8;
$tw-order-9: 9;
$tw-order-10: 10;
$tw-order-11: 11;
$tw-order-12: 12;
$tw-order-first: -9999;
$tw-order-last: 9999;
$tw-order-none: 0;

$tw-padding-0: 0;
$tw-padding-1: 0.25rem;
$tw-padding-2: 0.5rem;
$tw-padding-3: 0.75rem;
$tw-padding-4: 1rem;
$tw-padding-5: 1.25rem;
$tw-padding-6: 1.5rem;
$tw-padding-8: 2rem;
$tw-padding-10: 2.5rem;
$tw-padding-12: 3rem;
$tw-padding-16: 4rem;
$tw-padding-20: 5rem;
$tw-padding-24: 6rem;
$tw-padding-32: 8rem;
$tw-padding-40: 10rem;
$tw-padding-48: 12rem;
$tw-padding-56: 14rem;
$tw-padding-64: 16rem;
$tw-padding-px: 1px;

$tw-placeholderColor-light: #FFFFFF;
$tw-placeholderColor-dark: #1F2E3D;
$tw-placeholderColor-primary-default: #03699E;
$tw-placeholderColor-primary-light: #44BCE8;
$tw-placeholderColor-primary-dark: #203C66;
$tw-placeholderColor-secondary-default: #57D36C;
$tw-placeholderColor-gray-lighter: #F4F6F8;
$tw-placeholderColor-gray-light: #E2E8EE;
$tw-placeholderColor-gray-default: #BCC6CF;
$tw-placeholderColor-gray-dark: #5B636B;

$tw-placeholderOpacity-0: 0;
$tw-placeholderOpacity-25: 0.25;
$tw-placeholderOpacity-50: 0.5;
$tw-placeholderOpacity-75: 0.75;
$tw-placeholderOpacity-100: 1;

$tw-space-0: 0;
$tw-space-1: 0.25rem;
$tw-space-2: 0.5rem;
$tw-space-3: 0.75rem;
$tw-space-4: 1rem;
$tw-space-5: 1.25rem;
$tw-space-6: 1.5rem;
$tw-space-8: 2rem;
$tw-space-10: 2.5rem;
$tw-space-12: 3rem;
$tw-space-16: 4rem;
$tw-space-20: 5rem;
$tw-space-24: 6rem;
$tw-space-32: 8rem;
$tw-space-40: 10rem;
$tw-space-48: 12rem;
$tw-space-56: 14rem;
$tw-space-64: 16rem;
$tw-space-px: 1px;
$tw-space--1: -0.25rem;
$tw-space--2: -0.5rem;
$tw-space--3: -0.75rem;
$tw-space--4: -1rem;
$tw-space--5: -1.25rem;
$tw-space--6: -1.5rem;
$tw-space--8: -2rem;
$tw-space--10: -2.5rem;
$tw-space--12: -3rem;
$tw-space--16: -4rem;
$tw-space--20: -5rem;
$tw-space--24: -6rem;
$tw-space--32: -8rem;
$tw-space--40: -10rem;
$tw-space--48: -12rem;
$tw-space--56: -14rem;
$tw-space--64: -16rem;
$tw-space--px: -1px;

$tw-stroke-current: currentColor;

$tw-strokeWidth-0: 0;
$tw-strokeWidth-1: 1;
$tw-strokeWidth-2: 2;

$tw-textColor-light: #FFFFFF;
$tw-textColor-dark: #1F2E3D;
$tw-textColor-primary-default: #03699E;
$tw-textColor-primary-light: #44BCE8;
$tw-textColor-primary-dark: #203C66;
$tw-textColor-secondary-default: #57D36C;
$tw-textColor-gray-lighter: #F4F6F8;
$tw-textColor-gray-light: #E2E8EE;
$tw-textColor-gray-default: #BCC6CF;
$tw-textColor-gray-dark: #5B636B;

$tw-textOpacity-0: 0;
$tw-textOpacity-25: 0.25;
$tw-textOpacity-50: 0.5;
$tw-textOpacity-75: 0.75;
$tw-textOpacity-100: 1;

$tw-width-0: 0;
$tw-width-1: 0.25rem;
$tw-width-2: 0.5rem;
$tw-width-3: 0.75rem;
$tw-width-4: 1rem;
$tw-width-5: 1.25rem;
$tw-width-6: 1.5rem;
$tw-width-8: 2rem;
$tw-width-10: 2.5rem;
$tw-width-12: 3rem;
$tw-width-16: 4rem;
$tw-width-20: 5rem;
$tw-width-24: 6rem;
$tw-width-32: 8rem;
$tw-width-40: 10rem;
$tw-width-48: 12rem;
$tw-width-56: 14rem;
$tw-width-64: 16rem;
$tw-width-auto: auto;
$tw-width-px: 1px;
$tw-width-1\/2: 50%;
$tw-width-1\/3: 33.333333%;
$tw-width-2\/3: 66.666667%;
$tw-width-1\/4: 25%;
$tw-width-2\/4: 50%;
$tw-width-3\/4: 75%;
$tw-width-1\/5: 20%;
$tw-width-2\/5: 40%;
$tw-width-3\/5: 60%;
$tw-width-4\/5: 80%;
$tw-width-1\/6: 16.666667%;
$tw-width-2\/6: 33.333333%;
$tw-width-3\/6: 50%;
$tw-width-4\/6: 66.666667%;
$tw-width-5\/6: 83.333333%;
$tw-width-1\/12: 8.333333%;
$tw-width-2\/12: 16.666667%;
$tw-width-3\/12: 25%;
$tw-width-4\/12: 33.333333%;
$tw-width-5\/12: 41.666667%;
$tw-width-6\/12: 50%;
$tw-width-7\/12: 58.333333%;
$tw-width-8\/12: 66.666667%;
$tw-width-9\/12: 75%;
$tw-width-10\/12: 83.333333%;
$tw-width-11\/12: 91.666667%;
$tw-width-full: 100%;
$tw-width-screen: 100vw;

$tw-zIndex-0: 0;
$tw-zIndex-10: 10;
$tw-zIndex-20: 20;
$tw-zIndex-30: 30;
$tw-zIndex-40: 40;
$tw-zIndex-50: 50;
$tw-zIndex-auto: auto;

$tw-gap-0: 0;
$tw-gap-1: 0.25rem;
$tw-gap-2: 0.5rem;
$tw-gap-3: 0.75rem;
$tw-gap-4: 1rem;
$tw-gap-5: 1.25rem;
$tw-gap-6: 1.5rem;
$tw-gap-8: 2rem;
$tw-gap-10: 2.5rem;
$tw-gap-12: 3rem;
$tw-gap-16: 4rem;
$tw-gap-20: 5rem;
$tw-gap-24: 6rem;
$tw-gap-32: 8rem;
$tw-gap-40: 10rem;
$tw-gap-48: 12rem;
$tw-gap-56: 14rem;
$tw-gap-64: 16rem;
$tw-gap-px: 1px;

$tw-gridTemplateColumns-1: (repeat(1, minmax(0, 1fr)));
$tw-gridTemplateColumns-2: (repeat(2, minmax(0, 1fr)));
$tw-gridTemplateColumns-3: (repeat(3, minmax(0, 1fr)));
$tw-gridTemplateColumns-4: (repeat(4, minmax(0, 1fr)));
$tw-gridTemplateColumns-5: (repeat(5, minmax(0, 1fr)));
$tw-gridTemplateColumns-6: (repeat(6, minmax(0, 1fr)));
$tw-gridTemplateColumns-7: (repeat(7, minmax(0, 1fr)));
$tw-gridTemplateColumns-8: (repeat(8, minmax(0, 1fr)));
$tw-gridTemplateColumns-9: (repeat(9, minmax(0, 1fr)));
$tw-gridTemplateColumns-10: (repeat(10, minmax(0, 1fr)));
$tw-gridTemplateColumns-11: (repeat(11, minmax(0, 1fr)));
$tw-gridTemplateColumns-12: (repeat(12, minmax(0, 1fr)));
$tw-gridTemplateColumns-none: none;

$tw-gridColumn-auto: auto;
$tw-gridColumn-span-1: span 1 / span 1;
$tw-gridColumn-span-2: span 2 / span 2;
$tw-gridColumn-span-3: span 3 / span 3;
$tw-gridColumn-span-4: span 4 / span 4;
$tw-gridColumn-span-5: span 5 / span 5;
$tw-gridColumn-span-6: span 6 / span 6;
$tw-gridColumn-span-7: span 7 / span 7;
$tw-gridColumn-span-8: span 8 / span 8;
$tw-gridColumn-span-9: span 9 / span 9;
$tw-gridColumn-span-10: span 10 / span 10;
$tw-gridColumn-span-11: span 11 / span 11;
$tw-gridColumn-span-12: span 12 / span 12;

$tw-gridColumnStart-1: 1;
$tw-gridColumnStart-2: 2;
$tw-gridColumnStart-3: 3;
$tw-gridColumnStart-4: 4;
$tw-gridColumnStart-5: 5;
$tw-gridColumnStart-6: 6;
$tw-gridColumnStart-7: 7;
$tw-gridColumnStart-8: 8;
$tw-gridColumnStart-9: 9;
$tw-gridColumnStart-10: 10;
$tw-gridColumnStart-11: 11;
$tw-gridColumnStart-12: 12;
$tw-gridColumnStart-13: 13;
$tw-gridColumnStart-auto: auto;

$tw-gridColumnEnd-1: 1;
$tw-gridColumnEnd-2: 2;
$tw-gridColumnEnd-3: 3;
$tw-gridColumnEnd-4: 4;
$tw-gridColumnEnd-5: 5;
$tw-gridColumnEnd-6: 6;
$tw-gridColumnEnd-7: 7;
$tw-gridColumnEnd-8: 8;
$tw-gridColumnEnd-9: 9;
$tw-gridColumnEnd-10: 10;
$tw-gridColumnEnd-11: 11;
$tw-gridColumnEnd-12: 12;
$tw-gridColumnEnd-13: 13;
$tw-gridColumnEnd-auto: auto;

$tw-gridTemplateRows-1: (repeat(1, minmax(0, 1fr)));
$tw-gridTemplateRows-2: (repeat(2, minmax(0, 1fr)));
$tw-gridTemplateRows-3: (repeat(3, minmax(0, 1fr)));
$tw-gridTemplateRows-4: (repeat(4, minmax(0, 1fr)));
$tw-gridTemplateRows-5: (repeat(5, minmax(0, 1fr)));
$tw-gridTemplateRows-6: (repeat(6, minmax(0, 1fr)));
$tw-gridTemplateRows-none: none;

$tw-gridRow-auto: auto;
$tw-gridRow-span-1: span 1 / span 1;
$tw-gridRow-span-2: span 2 / span 2;
$tw-gridRow-span-3: span 3 / span 3;
$tw-gridRow-span-4: span 4 / span 4;
$tw-gridRow-span-5: span 5 / span 5;
$tw-gridRow-span-6: span 6 / span 6;

$tw-gridRowStart-1: 1;
$tw-gridRowStart-2: 2;
$tw-gridRowStart-3: 3;
$tw-gridRowStart-4: 4;
$tw-gridRowStart-5: 5;
$tw-gridRowStart-6: 6;
$tw-gridRowStart-7: 7;
$tw-gridRowStart-auto: auto;

$tw-gridRowEnd-1: 1;
$tw-gridRowEnd-2: 2;
$tw-gridRowEnd-3: 3;
$tw-gridRowEnd-4: 4;
$tw-gridRowEnd-5: 5;
$tw-gridRowEnd-6: 6;
$tw-gridRowEnd-7: 7;
$tw-gridRowEnd-auto: auto;

$tw-transformOrigin-center: center;
$tw-transformOrigin-top: top;
$tw-transformOrigin-top-right: top right;
$tw-transformOrigin-right: right;
$tw-transformOrigin-bottom-right: bottom right;
$tw-transformOrigin-bottom: bottom;
$tw-transformOrigin-bottom-left: bottom left;
$tw-transformOrigin-left: left;
$tw-transformOrigin-top-left: top left;

$tw-scale-0: 0;
$tw-scale-50: .5;
$tw-scale-75: .75;
$tw-scale-90: .9;
$tw-scale-95: .95;
$tw-scale-100: 1;
$tw-scale-105: 1.05;
$tw-scale-110: 1.1;
$tw-scale-125: 1.25;
$tw-scale-150: 1.5;

$tw-rotate-0: 0;
$tw-rotate-45: 45deg;
$tw-rotate-90: 90deg;
$tw-rotate-180: 180deg;
$tw-rotate--180: -180deg;
$tw-rotate--90: -90deg;
$tw-rotate--45: -45deg;

$tw-translate-0: 0;
$tw-translate-1: 0.25rem;
$tw-translate-2: 0.5rem;
$tw-translate-3: 0.75rem;
$tw-translate-4: 1rem;
$tw-translate-5: 1.25rem;
$tw-translate-6: 1.5rem;
$tw-translate-8: 2rem;
$tw-translate-10: 2.5rem;
$tw-translate-12: 3rem;
$tw-translate-16: 4rem;
$tw-translate-20: 5rem;
$tw-translate-24: 6rem;
$tw-translate-32: 8rem;
$tw-translate-40: 10rem;
$tw-translate-48: 12rem;
$tw-translate-56: 14rem;
$tw-translate-64: 16rem;
$tw-translate-px: 1px;
$tw-translate--1: -0.25rem;
$tw-translate--2: -0.5rem;
$tw-translate--3: -0.75rem;
$tw-translate--4: -1rem;
$tw-translate--5: -1.25rem;
$tw-translate--6: -1.5rem;
$tw-translate--8: -2rem;
$tw-translate--10: -2.5rem;
$tw-translate--12: -3rem;
$tw-translate--16: -4rem;
$tw-translate--20: -5rem;
$tw-translate--24: -6rem;
$tw-translate--32: -8rem;
$tw-translate--40: -10rem;
$tw-translate--48: -12rem;
$tw-translate--56: -14rem;
$tw-translate--64: -16rem;
$tw-translate--px: -1px;
$tw-translate--full: -100%;
$tw-translate--1\/2: -50%;
$tw-translate-1\/2: 50%;
$tw-translate-full: 100%;

$tw-skew-0: 0;
$tw-skew-3: 3deg;
$tw-skew-6: 6deg;
$tw-skew-12: 12deg;
$tw-skew--12: -12deg;
$tw-skew--6: -6deg;
$tw-skew--3: -3deg;

$tw-transitionProperty-none: none;
$tw-transitionProperty-all: all;
$tw-transitionProperty-default: (background-color, border-color, color, fill, stroke, opacity, box-shadow, transform);
$tw-transitionProperty-colors: (background-color, border-color, color, fill, stroke);
$tw-transitionProperty-opacity: opacity;
$tw-transitionProperty-shadow: box-shadow;
$tw-transitionProperty-transform: transform;

$tw-transitionTimingFunction-linear: linear;
$tw-transitionTimingFunction-in: (cubic-bezier(0.4, 0, 1, 1));
$tw-transitionTimingFunction-out: (cubic-bezier(0, 0, 0.2, 1));
$tw-transitionTimingFunction-in-out: (cubic-bezier(0.4, 0, 0.2, 1));

$tw-transitionDuration-75: 75ms;
$tw-transitionDuration-100: 100ms;
$tw-transitionDuration-150: 150ms;
$tw-transitionDuration-200: 200ms;
$tw-transitionDuration-300: 300ms;
$tw-transitionDuration-500: 500ms;
$tw-transitionDuration-700: 700ms;
$tw-transitionDuration-1000: 1000ms;

$tw-transitionDelay-75: 75ms;
$tw-transitionDelay-100: 100ms;
$tw-transitionDelay-150: 150ms;
$tw-transitionDelay-200: 200ms;
$tw-transitionDelay-300: 300ms;
$tw-transitionDelay-500: 500ms;
$tw-transitionDelay-700: 700ms;
$tw-transitionDelay-1000: 1000ms;
