.hero {
	&__wrapper {

		.overlay {
			position: absolute;
			background: linear-gradient(90deg, rgba(32, 60, 102, .5) 0%, rgba(68, 188, 232, .5) 100%);
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 15;
		}

		.hero-swoosh {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 25%;
			z-index: 17;
			background-image: url('/wp-content/themes/_view/dist/svgs/hero-swoosh.svg');
			background-position: left center;
			background-size: cover;
		}

	}
}